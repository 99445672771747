import React from "react"
import styled from "styled-components"
const StyledContainer = styled.div`
  p {
    margin-block-end: 1rem;
  }
`
export default function Answer({ component }) {
  return <StyledContainer>{component}</StyledContainer>
}
