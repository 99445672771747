import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ContentContainer from "../../../components/ContentContainer"
import QA from "./QA"
import LinkText from "./LinkText"
import Notification from "./Notification"
import { MainVisualWithCassis } from "../../../components/MainVisualWithCassis"
import { useQuery } from "../hooks"
import { title, subTitle, qa } from "../data.yaml"

export default function QaOnRawMaterialsAndAdditives() {
  const { thumbnail } = useQuery()
  return (
    <>
      <MainVisualWithCassis
        title={title}
        subTitle={subTitle}
        bgColor="#b41e2b"
      />
      <ContentContainer>
        <QA
          questionNum={qa.q1.number}
          question={qa.q1.question}
          answer={
            <>
              <p>
                {qa.q1.answer.line1}
                <br />
                {qa.q1.answer.line2}
              </p>
              <LinkText link={qa.q1.link} />
            </>
          }
        />
        <QA
          questionNum={qa.q2.number}
          question={qa.q2.question}
          answer={
            <p>
              {qa.q2.answer.line1}
              <br />
              {qa.q2.answer.line2}
              <Notification text={qa.q2.answer.notification} />
            </p>
          }
        />
        <QA
          questionNum={qa.q3.number}
          question={qa.q3.question}
          answer={
            <p>
              {qa.q3.answer.line1}
              <br />
              {qa.q3.answer.line2}
              <br />
              {qa.q3.answer.line3}
            </p>
          }
        />
        <QA
          questionNum={qa.q4.number}
          question={qa.q4.question}
          answer={
            <p>
              {qa.q4.answer.line1}
              <br />
              {qa.q4.answer.line2}
              <br />
              {qa.q4.answer.line3}
            </p>
          }
        />

        <QA
          questionNum={qa.q5.number}
          question={qa.q5.question}
          answer={
            <>
              <p>
                {qa.q5.answer.line1}
                <br />
                {qa.q5.answer.line2}
              </p>
              <LinkText link={qa.q5.link} isExternal={true} />
            </>
          }
        />
        <QA
          questionNum={qa.q6.number}
          question={qa.q6.question}
          answer={
            <>
              <p>
                {qa.q6.answer.line1}
                <br />
                {qa.q6.answer.line2}
              </p>

              <LinkText link={qa.q6.link} isExternal={true} />
            </>
          }
        />
        <QA
          questionNum={qa.q7.number}
          question={qa.q7.question}
          answer={
            <p>
              {qa.q7.answer.line1}
              <br />
              {qa.q7.answer.line2}
            </p>
          }
        />
        <QA
          questionNum={qa.q8.number}
          question={qa.q8.question}
          answer={
            <>
              <p>
                {qa.q8.answer.line1}
                {qa.q8.answer.notifications.map((notification, index) => (
                  <Notification key={index} text={notification} />
                ))}
              </p>

              <LinkText
                link={qa.q8.link}
                isExternal={true}
                thumbnail={thumbnail.kurashi}
              />
            </>
          }
        />
        <QA
          questionNum={qa.q9.number}
          question={qa.q9.question}
          answer={
            <>
              <p>
                {qa.q9.answer.line1}
                <br />
                {qa.q9.answer.line2}
              </p>
              <LinkText
                link={qa.q9.link}
                isExternal={true}
                thumbnail={thumbnail.slowbread}
              />
            </>
          }
        />
        <QA
          questionNum={qa.q10.number}
          question={qa.q10.question}
          answer={<p>{qa.q10.answer.line1}</p>}
        />
        <QA
          questionNum={qa.q11.number}
          question={qa.q11.question}
          answer={
            <p>
              {qa.q11.answer.line1}
              <br />
              {qa.q11.answer.line2}
            </p>
          }
        />
      </ContentContainer>
    </>
  )
}
