import React from "react"
import styled from "styled-components"

const StyledNotification = styled.span`
  /* margin: 0; */
  margin-inline-start: 1rem;
  display: flex;
`

export default function Notification({ text }) {
  return <StyledNotification>{text}</StyledNotification>
}
