import React from "react"
import styled, { css } from "styled-components"
import GatsbyImage from "gatsby-image"
import Styled from "styled-components"
import { LinkTextWithArrow } from "../../../components/Link"

// import WithThumbnailLinkText from "./WithThumbnailLinkText"
import { LinkTextWithThumbnail } from "../../../components/Link"
import { media } from "../../../styles/media"

const StyledContainer = Styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-inline-start: 2rem;
  ${() =>
    media.sp(css`
      margin-inline-start: 0;
      align-items: center;
    `)}
`

const StyledImageContainer = styled.div`
  width: 200px;
`

export default function LinkText({
  link,
  isExternal = false,
  thumbnail = null,
}) {
  if (thumbnail) {
    return (
      <StyledContainer>
        <LinkTextWithThumbnail
          text={link.text}
          href={link.url}
          styles={{ fontSize: "1rem" }}
          thumbnailComponent={
            <StyledImageContainer>
              <GatsbyImage fluid={thumbnail.srcset} alt={thumbnail.alt} />
            </StyledImageContainer>
          }
        />
      </StyledContainer>
    )
  }
  return (
    <StyledContainer>
      <LinkTextWithArrow
        text={link.text}
        href={link.url}
        isExternal={isExternal}
        styles={{ fontSize: "1rem" }}
      />
    </StyledContainer>
  )
}
