import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { QaOnRawMaterialsAndAdditives } from "../features/qa-on-raw-materials-and-additives-page/components"

export default function QaOnRawMaterialsAndAdditivesPage() {
  return (
    <Layout>
      <SEO title="原料・添加物に関するQ&A" />
      <QaOnRawMaterialsAndAdditives />
    </Layout>
  )
}
