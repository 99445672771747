import React from "react"
import styled from "styled-components"
import Question from "./Question"
import Answer from "./Answer"

const StyledContainer = styled.div`
  margin-block-end: 4rem;
  p {
    /* margin-block-start: 0.5rem;
    margin-block-end: 1rem; */
    line-height: 1.4;
  }
`

export default function QA({ questionNum, question, answer }) {
  return (
    <StyledContainer>
      <Question questionNum={questionNum} question={question} />
      <Answer component={answer} />
    </StyledContainer>
  )
}
