import { graphql, useStaticQuery } from "gatsby"

export default function useQuery() {
  const { mainVisual, kurashiImg, slowbreadImg } = useStaticQuery(graphql`
    query {
      kurashiImg: file(relativePath: { eq: "kurashi-online.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slowbreadImg: file(relativePath: { eq: "slowbread-thumbnail.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const thumbnail = {
    kurashi: {
      srcset: kurashiImg.childImageSharp.fluid,
      alt: "kurashi.online website image",
    },
    slowbread: {
      srcset: slowbreadImg.childImageSharp.fluid,
      alt: "slowbread website image",
    },
  }
  return { mainVisual, thumbnail }
}
