import React from "react"
import styled from "styled-components"
const StyledQuestion = styled.p`
  color: #1d2088;
  font-weight: bold;
  margin-block-end: 0.5rem;
  text-decoration: underline;
  text-underline-offset: 4px;
`
export default function Question({ questionNum, question }) {
  return (
    <StyledQuestion>
      {questionNum}&nbsp;&nbsp;&nbsp;{question}
    </StyledQuestion>
  )
}
